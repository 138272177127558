import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div`
  max-width: 1000px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `
          max-width: 1150px;
        `;
    }
  }}
`;

export const Video = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;
export const Iframe = styled.iframe`
  min-height: 600px;
  border: 0;

  @media ${queries.lg} {
    min-height: 400px;
  }

  @media ${queries.md} {
    min-height: 195px;
  }
`;
