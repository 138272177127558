import React, { useContext } from 'react';
import * as S from './Youtube.styles';
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import FunnelContext from '../../context/FunnelContext';

const Embed = props => {
  const { video_id, provider } = props;
  const defaultPlay = props?.defaultPlay || false;
  const isPlaylist = props?.isPlaylist || false;
  const noCookie = props?.noCookie || true;
  const mute = props?.mute || false;
  const isMobile = props?.isMobile || false;
  const mobileResolution = props?.mobileResolution || 'hqdefault';
  const desktopResolution = props?.desktopResolution || 'maxresdefault';
  const lazyImage = props?.lazyImage || false;
  const imageAltText =
    props?.imageAltText || "YouTube's thumbnail for this video.";
  const iframeTitle = props?.iframeTitle || 'YouTube video.';

  switch (provider) {
    default:
    case 'youtube':
      return (
        <LiteYoutubeEmbed
          id={video_id}
          defaultPlay={defaultPlay}
          isPlaylist={isPlaylist}
          noCookie={noCookie}
          mute={mute}
          isMobile={isMobile}
          mobileResolution={mobileResolution}
          desktopResolution={desktopResolution}
          lazyImage={lazyImage}
          imageAltText={imageAltText}
          iframeTitle={iframeTitle}
        />
      );
  }
};

const Video2 = props => {
  const { pageDesign } = useContext(FunnelContext);

  return (
    <S.Container pageDesign={pageDesign}>
      <S.Video pageDesign={pageDesign}>
        <Embed {...props} />
      </S.Video>
    </S.Container>
  );
};

export default Video2;
