import React, { useCallback, useEffect } from 'react';

const Vidalytics = props => {
  const { vidalyticsId, vidalyticsUrl, secondVideo } = props;

  const loadVidalytics = useCallback((v, i, d, a, l, y, t, c, s) => {
    y = '_' + d.toLowerCase();
    c = d + 'L';
    if (!v[d]) {
      v[d] = {};
    }
    if (!v[c]) {
      v[c] = {};
    }
    if (!v[y]) {
      v[y] = {};
    }
    var vl = 'Loader',
      vli = v[y][vl],
      vsl = v[c][vl + 'Script'],
      ve = 'Embed';
    if (!vsl) {
      vsl = function(u, cb) {
        if (t) {
          cb();
          return;
        }
        s = i.createElement('script');
        s.type = 'text/javascript';
        s.async = 1;
        s.src = u;
        if (s.readyState) {
          s.onreadystatechange = function() {
            if (s.readyState === 'loaded' || s.readyState === 'complete') {
              s.onreadystatechange = null;
              v[c][vl + 'Loaded'] = 1;
              cb();
            }
          };
        } else {
          s.onload = function() {
            v[c][vl + 'Loaded'] = 1;
            cb();
          };
        }
        i.getElementsByTagName('head')[0].appendChild(s);
      };
    }
    vsl(l + 'loader.min.js', function() {
      if (!vli) {
        var vlc = v[c][vl];
        vli = new vlc();
      }
      vli.loadScript(l + 'player.min.js?noautoplay=true', function() {
        var vec = v[d][ve];
        t = new vec();
        t.run(a);
      });
    });
  }, []);

  const loadVidalyticsSecondVideo = useCallback((v, d, a, l, y, t, c, s) => {
    y = '_' + d.toLowerCase();
    if (!v[y]) {
      v[y] = {};
    }
    if (!v[y].embeds) {
      v[y].embeds = {};
    }
    t = function() {
      if (v[d] && v[d].Embed) {
        var ve = v[d].Embed;
        c = new ve();
        c.run(a);
        c.loadCss();
      } else {
        setTimeout(t, 1000);
      }
    };
    s = new XMLHttpRequest();
    s.open('GET', l + '?ac=' + new Date().getTime(), true);
    s.onreadystatechange = function() {
      if (s.readyState === 4) {
        if (s.status === 200 || s.status === 304) {
          var sd = JSON.parse(s.responseText);
          v[y].embeds[a] = { type: 'video', options: sd };
          t();
        }
      }
    };
    s.send();
  }, []);

  useEffect(() => {
    if (secondVideo) {
      loadVidalyticsSecondVideo(
        window,
        'Vidalytics',
        vidalyticsId.replace('vidalytics_embed_', ''),
        `${vidalyticsUrl}player.settings.json`
      );
    } else {
      loadVidalytics(
        window,
        document,
        'Vidalytics',
        vidalyticsId,
        vidalyticsUrl
      );
    }
  }, [
    loadVidalytics,
    loadVidalyticsSecondVideo,
    vidalyticsId,
    vidalyticsUrl,
    secondVideo
  ]);

  return (
    <div
      id={
        secondVideo
          ? vidalyticsId.replace('vidalytics_embed_', '')
          : vidalyticsId
      }
    ></div>
  );
};

export default Vidalytics;
